/* ChartPlus version1.0.0 2021-09-23 */
/*.chartPlus-axis line,
.chartPlus-axis path {
    stroke: #767676;
    fill: none;
    stroke-width: 1px;
    shape-rendering: crispEdges;
}

.chartPlus-axis text {
    font-size: 12px;
    font-weight: lighter;
    fill: #767676;
}

.axisTitle text {
    font-size: 14px;
    font-weight: lighter;
    fill: #767676;
}

.hidden {
    opacity: 0;
    pointer-events: none;
}
*/

.chartPlus-bar:hover {
  fill-opacity: 0.8;
}

.labelclassouter {
  fill: #707070 !important;
  font-family: Arial !important;
  font-size: 11px !important;
  opacity: 1;
}
.labelclassinner {
  fill: #fff !important;
  font-family: Arial !important;
  font-size: 11px !important;
  opacity: 1;
}

/*.chartPlus-line {
  fill: none;
  stroke-width: 2;
  shape-rendering: auto;
}

.chartPlus-line.indicator {
  stroke-width: 4;
}

.chartPlus-marker.small {
  stroke-width: 0;
}

.chartPlus-marker.highlight {
  stroke-width: 2;
  fill: #ffffff;
}

.chartPlus-marker.highlight:hover {
  stroke-width: 4;
}

.chartPlus-marker.small:hover {
  stroke-width: 7;
  stroke-opacity: 0.3;
}
*/
.linelabelclass {
  fill: #707070 !important;
  font-family: Arial !important;
  font-size: 11px !important;
  opacity: 1;
}

/*.arc {
  stroke: #ffffff;
  stroke-width: 0.5px;
  fill-opacity: 1;
}

.arc:hover {
  fill-opacity: 0.8;
}

.arc:hover ~ .outerArc {
  fill-opacity: 0.5;
}

.outerArc {
  stroke: #ffffff;
  stroke-width: 0.5px;
  fill-opacity: 0;
}

.outerArc:hover {
  fill-opacity: 0.5;
}*/

/*.chartPlus-arc {
  stroke: #ffffff;
  stroke-width: 0.5px;
  fill-opacity: 1;
}

.chartPlus-arc:hover {
  fill-opacity: 0.8;
}

.chartPlus-arc:hover ~ .outerArc {
  fill-opacity: 0.5;
}

.chartPlus-outerArc {
  stroke: #ffffff;
  stroke-width: 0.5px;
  fill-opacity: 0;
}

.chartPlus-outerArc:hover {
  fill-opacity: 0.5;
}
*/

.chartPlus-tooltip {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  text-align: left;
  pointer-events: none;
  border-radius: 4px;
  background: #151515;
  color: #ffffff;
  padding: 5px;
  font-size: 10px;
  font-weight: 500;
  max-width: 300px;
}

.chartPlus-tooltip span {
  /*white-space: nowrap;*/
  word-wrap: break-word;
}

.chartPlus-tooltip-hidden {
  visibility: hidden;
  opacity: 0;
}

.chartPlus-tooltip-visible {
  visibility: visible;
  opacity: 0.9;
}

.chartPlus-allEvents {
  pointer-events: all;
}

.chartPlus-tooltip-actions {
  margin-top: 3px;
  cursor: pointer;
}

.chartPlus-tooltip-close {
  float: right;
  cursor: pointer;
}

/*.chartPlus-legend {
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  fill: #000000;
}

.cursorPointer {
  cursor: pointer;
}
.clipLegend {
  clip-path: url(#legendClip);
}

.disabled {
  fill: #cccccc;
}
*/

.chartPlus-drillPath {
  /*font-family: 'gotham-rounded-book';*/
  font-weight: 600;
  fill: lightgrey;
  font-size: 14px;
}

/*.active .icon {
  fill: #3e6273;
}*/

.chartPlus-active .name {
  fill: #3572b0;
}

.chartPlus-drillUp.name {
  /*fill: #3e6273;*/
  cursor: pointer;
}

.chartPlus-drillUp:hover {
  fill: #3572b0;
  text-decoration: underline;
}

.chartPlus-drillDown {
  cursor: pointer;
}

.chartPlus-key {
  /*font-family: 'gotham-light';*/
  font-size: 11px;
  font-weight: 500;
  /*fill: #3e6273;*/
}

.chartPlus-value {
  fill: #3572b0 !important;
  /*font-family: 'gotham-rounded-book';*/
  font-size: 11px;
  cursor: default;
}

.chartPlus-hierarchyClip {
  clip-path: url(#hierarchyClip);
}

.chartPlus-pageUp {
  fill: black;
  cursor: pointer;
}

.chartPlus-pageDown {
  fill: black;
  cursor: pointer;
}

.chartPlus-pageDisabled {
  fill: lightgrey;
  cursor: default;
}

.chartPlus-pageCount {
  /*font-family: 'gotham-light';*/
  font-size: 11px;
  /*fill: #3e6273;*/
}

.chartPlus-sparkline {
  fill: none;
  stroke-width: 2px;
}

.cPlus-tHead {
  color: #737373;
  font-size: 14px;
  text-transform: capitalize;
  border-bottom: 2px solid #737373;
  width: 100%;
}

.cPlus-tHead th {
  font-weight: lighter;
  text-align: left;
  padding: 5px 10px 5px 5px;
}
.cPlus-tHead th:hover {
  cursor: pointer;
  opacity: 0.7;
}

.cPlus-tHead div {
  font-family: 'Helvetica';
}

.cPlus-tBody {
  text-align: left;
  word-wrap: break-word;
  word-break: normal;
  overflow: auto;
  width: 100%;
}

.cPlus-tBody td {
  padding: 3px 10px 3px 5px;
}

.cPlus-tBody div {
  font-family: 'Helvetica';
}

.cPlus-tBody .mainRow {
  color: #72787a;
  font-size: 14px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.clickabletd {
  color: #72787a;
}

.clickabletd:hover {
  color: #4ca4f4;
}

.cPlus-tBody .subRow {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: lighter;
}

.cPlus-scrollContainer {
  position: relative;
  width: 100%;
}

.cPlus-scrollArea {
  overflow: auto;
  border-top: 1px solid #ddd;
}
.chartPlus-container {
  scrollbar-width: thin;
}
.chartPlus-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.chartPlus-container ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.chartPlus-container::-webkit-scrollbar-thumb {
  background: #b2b2b2;
}

/* Handle on hover */
.chartPlus-container::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.totalRow {
  color: #737373;
  font-size: 14px;
  border-top: 2px solid #737373;
  font-weight: bold;
}
.testselect {
  font-family: Helvetica;
  display: flex;
  width: inherit;
  justify-content: center;
}
/*Sort indicator*/

.arrow-up {
  width: 0;
  height: 6px;
  border-left: 2.5px solid transparent;
  border-right: 2.5px solid transparent;
  border-bottom: 2.5px solid #888;
  flex: 0;
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 2.5px solid transparent;
  border-right: 2.5px solid transparent;
  border-top: 2.5px solid #888;
  flex: 0;
  margin-top: 6px;
}

/*sparkline table*/

.cPlus-scrollBody {
  overflow: auto;
  border-top: 1px solid #ddd;
  position: relative;
  width: 100%;
  height: 100%;
}

.cPlus-scrollBody .chart {
  min-width: 50px;
  max-width: 200px;
}

.cPlus-tBody .chart {
  height: 35px;
  padding: 5px;
}

.cPlus-tBody .chart svg {
  position: relative;
}

.horizontalbar-grey-fill {
  fill: #ccc !important;
}
.Hchartlabelclass {
  fill: #fff !important;
  font-size: 11px !important;
  opacity: 1;
}
svg:not(:root) {
  overflow: hidden;
}

.chartPlus-container {
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: top;
  overflow: hidden;
}

.chartPlus-container svg {
  position: absolute;
  top: 0;
  left: 0;
}

.chartPlus-grey-fill {
  fill: #cccccc !important;
}

.chartPlus-grey-stroke {
  stroke: #cccccc !important;
}
